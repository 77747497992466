var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c("div", { staticClass: "section-content" }, [
      _c(
        "div",
        { staticClass: "list-container" },
        [
          _c(
            "div",
            [
              _c("loading", {
                attrs: {
                  active: _vm.isLoading,
                  "can-cancel": false,
                  "is-full-page": _vm.fullPage,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.isLoading = $event
                  },
                },
              }),
            ],
            1
          ),
          _c("b-card", [
            _c(
              "div",
              { staticClass: "form-style" },
              [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Nome:",
                          "label-for": "nested-document",
                          "label-align-sm": "left",
                          "label-cols-sm": "2",
                          state: _vm.validates.name,
                          "invalid-feedback": _vm.advancedNameInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "nested-name" },
                          model: {
                            value: _vm.user.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "name", $$v)
                            },
                            expression: "user.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Telefone:",
                          "label-for": "nested-phone",
                          "label-align-sm": "left",
                          "content-cols-lg": "4",
                          "label-cols-sm": "2",
                          state: _vm.validates.phone,
                          "invalid-feedback": _vm.advancedPhoneInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##) #####-####"],
                              expression: "['(##) #####-####']",
                            },
                          ],
                          staticClass: "col-sm-8",
                          attrs: { id: "nested-phone" },
                          model: {
                            value: _vm.user.phone,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "phone", $$v)
                            },
                            expression: "user.phone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Email:",
                          "label-for": "nested-email",
                          "label-align-sm": "left",
                          "content-cols-lg": "4",
                          "label-cols-sm": "2",
                          state: _vm.validates.email,
                          "invalid-feedback": _vm.advancedEmailInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "col-sm-8",
                          attrs: { id: "nested-email", state: null },
                          model: {
                            value: _vm.user.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "email", $$v)
                            },
                            expression: "user.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Empresa:",
                          "label-for": "nested-company-name",
                          "label-align-sm": "left",
                          "label-cols-sm": "2",
                          state: _vm.validates.companyName,
                          "invalid-feedback":
                            _vm.advancedCompanyNameInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { id: "nested-company-name" },
                          model: {
                            value: _vm.user.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "companyName", $$v)
                            },
                            expression: "user.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Número de usuários:",
                          "label-for": "nested-users-number",
                          "label-align-sm": "left",
                          "content-cols-lg": "4",
                          "label-cols-sm": "2",
                          state: _vm.validates.usersNumber,
                          "invalid-feedback":
                            _vm.advancedNumberUserInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "col-sm-8",
                          attrs: { id: "nested-users-number", type: "number" },
                          model: {
                            value: _vm.user.usersNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "usersNumber", $$v)
                            },
                            expression: "user.usersNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "* Estimativa de horas por mês:",
                          "label-for": "nested-hours-estimate",
                          "label-align-sm": "left",
                          "content-cols-lg": "4",
                          "label-cols-sm": "2",
                          state: _vm.validates.hoursEstimate,
                          "invalid-feedback":
                            _vm.advancedHoursEstimateInvalidFeedback,
                        },
                      },
                      [
                        _c("b-form-input", {
                          staticClass: "col-sm-8",
                          attrs: {
                            id: "nested-hours-estimate",
                            type: "number",
                          },
                          model: {
                            value: _vm.user.hoursEstimate,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "hoursEstimate", $$v)
                            },
                            expression: "user.hoursEstimate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Observação:",
                          "label-for": "nested-observation",
                          "label-align-sm": "left",
                          "content-cols-lg": "4",
                          "label-cols-sm": "2",
                          state: _vm.validates.observation,
                        },
                      },
                      [
                        _c("b-textarea", {
                          staticClass: "col-sm-12",
                          attrs: { id: "nested-observation" },
                          model: {
                            value: _vm.user.observation,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "observation", $$v)
                            },
                            expression: "user.observation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", [
                      _c("ul", [
                        _c(
                          "li",
                          { staticClass: "list-inline-item" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: { click: _vm.onSendClickButton },
                              },
                              [_vm._v("Enviar")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Plano Avançado")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }